import React from "react"
import {Pagination, PaginationItem, PaginationLink} from "reactstrap"

const PaginationLinks = ({currentPage, numberOfPages}) => {
  const isFirst = currentPage === 1
  const isLast = currentPage === numberOfPages
  const previousPage =
    currentPage - 1 === 1 ? "/blog" : "/blog/" + (currentPage - 1).toString()
  const nextPage = "/blog/" + (currentPage + 1).toString()
  const lastPage = "/blog/" + numberOfPages.toString()
  return (
    <Pagination size="lg">
      {isFirst ? (
        <PaginationItem disabled>
          <PaginationLink first href="#"></PaginationLink>
        </PaginationItem>
      ) : (
        <PaginationItem>
		<PaginationLink first href="/blog/"></PaginationLink>
        </PaginationItem>
      )}
      {isFirst ? (
        <PaginationItem disabled>
          <PaginationLink previous href="#"></PaginationLink>
        </PaginationItem>
      ) : (
        <PaginationItem>
		<PaginationLink previous href={previousPage + `/`}></PaginationLink>
        </PaginationItem>
      )}
      {/*
		{Array.from({ length: numberOfPages }, (_, i) => currentPage === i + 1 ? (
			<PaginationItem active key={`page-number${i + 1}`}>
				<PaginationLink href={`/${i === 0 ? 'blog' : 'blog/' + (i +1)}`}>
					{i + 1}
				</PaginationLink>
			</PaginationItem>
		) : (
			<PaginationItem key={`page-number${i + 1}`}>
				<PaginationLink href={`/${i === 0 ? 'blog' : 'blog/' + (i +1)}`}>
					{i + 1}
				</PaginationLink>
			</PaginationItem>

		))}
*/}

      {isFirst ? null : (
        <PaginationItem disabled>
          <PaginationLink href="#">...</PaginationLink>
        </PaginationItem>
      )}
      {isFirst ? null : (
        <PaginationItem>
		<PaginationLink href={previousPage + `/`}>{currentPage - 1}</PaginationLink>
        </PaginationItem>
      )}
      <PaginationItem active>
        <PaginationLink href="#">{currentPage}</PaginationLink>
      </PaginationItem>
      {isLast ? null : (
        <PaginationItem>
		<PaginationLink href={nextPage + `/`}>{currentPage + 1}</PaginationLink>
        </PaginationItem>
      )}
      {isLast ? null : (
        <PaginationItem disabled>
          <PaginationLink href="#">...</PaginationLink>
        </PaginationItem>
      )}
      {isLast ? (
        <PaginationItem disabled>
          <PaginationLink next href="#"></PaginationLink>
        </PaginationItem>
      ) : (
        <PaginationItem>
		<PaginationLink next href={nextPage + `/`}></PaginationLink>
        </PaginationItem>
      )}
      {isLast ? (
        <PaginationItem disabled>
          <PaginationLink last href="#"></PaginationLink>
        </PaginationItem>
      ) : (
        <PaginationItem>
		<PaginationLink last href={lastPage + `/`}></PaginationLink>
        </PaginationItem>
      )}
    </Pagination>
  )
}

export default PaginationLinks
