/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import {StaticQuery, graphql} from "gatsby"

import Header from "./header"
import Footer from "./footer"
import FooterSM from "./sm-footer"
import JoinThe from "./jointhe"

import styled from "styled-components"

import "../styles/styles.scss"

import {colors, fonts} from "../../theme.js"

const ArchiveLayout = ({children}) => (
  <StaticQuery
    query={graphql`
      query BlogNameQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} />
        <BlogRollBG>
          <main>{children}</main>
        </BlogRollBG>
        <JoinThe />
        <FooterSM />
        <Footer />
      </>
    )}
  />
)

ArchiveLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ArchiveLayout

const BlogRollBG = styled.div`
  background-image: linear-gradient(
    316deg,
    ${colors.meatMedium} 0%,
    #131313 84%
  );
//padding-top: 20px;
  padding-bottom: 20px;
`
