import React from "react"
import {Link} from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import {slugify} from "../util/utilityFunctions"
import {
  Row,
  Col,
  Card,
  CardTitle,
  CardText,
  CardSubtitle,
  CardBody,
  Badge,
} from "reactstrap"

import {colors, fonts} from "../../theme.js"

const getColor = tag => {
  if (tag === "Spanish") {
    return "badge-spanish text-uppercase"
  } else if (tag === "French") {
    return "badge-french text-uppercase"
  } else if (tag === "Chinese") {
    return "badge-chinese text-uppercase"
  } else if (tag === "German") {
    return "badge-german text-uppercase"
  } else if (tag === "Politics") {
    return "badge-politics text-uppercase"
  } else {
    return "badge-generic text-uppercase"
  }
}

const Post = ({title, author, tags, path, date, body, fluid, sizes, slug}) => (
  <Link to={slug}>
    <Card className="blog-tiles">
      <CardBody>
        <Row>
          <Col sm="6">
            <GatsbyImage image={sizes} alt={title} />
          </Col>
          <Col sm="6">
            <CardTitle style={{marginBottom: `0`}}>
              <h3 style={{marginBottom: `0.1rem`}}>{title}</h3>
            </CardTitle>
            <CardSubtitle>
              <small
                style={{
                  color: colors.secondaryColor,
                  fontSize: `60%`,
                  textTransform: `uppercase`,
                }}>
                {date}
              </small>
            </CardSubtitle>
            <CardText>{body}</CardText>
            <ul className="post-tags">
              {tags.map(tag => (
                <li key={tag}>
                  <Link to={`/category/${slugify(tag)}`}>
                    <Badge
                      className={getColor(tag)}
                      style={{fontSize: `60%`}}
                      pill>
                      {tag}
                    </Badge>
                  </Link>
                </li>
              ))}
            </ul>
          </Col>
        </Row>
      </CardBody>
    </Card>
  </Link>
)

export default Post
